/* header */
.navbar {
  background-color: var(--color-white);
}

.navbar-brand {
  /* transform: translateX(-50%);
  left: 50%;
  position: absolute;
  padding-top: var(--spacing-12); */
  margin: auto;
}
