.search-wrapper .search-box {
  margin: var(--spacing-48);
  text-align: center;
  border-radius: var(--spacing-24);
}

.search-wrapper .search-box .input-group-prepend {
  border: 0px !important;
  background: var(--color-white) !important;
  border-radius: var(--spacing-24);
}

.search-wrapper .search-box .input-group-prepend span {
  border: 1px solid var(--color-gray-100);
  background: var(--color-white) !important;
  padding: var(--spacing-20);
  border-top-left-radius: var(--spacing-24);
  border-bottom-left-radius: var(--spacing-24);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.search-wrapper .search-box-wrapper {
  position: relative;
}

.search-wrapper .search-box-wrapper input {
  border: 1px solid var(--color-gray-100);
  background: var(--color-white) !important;
  border-top-right-radius: var(--spacing-24);
  border-bottom-right-radius: var(--spacing-24);
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important; */
  text-indent: var(--spacing-12);
}

.search-wrapper .search-box-wrapper .search-results {
  max-height: 30vh;
  position: absolute;
  top: 3.25em;
  background-color: var(--color-white);
  text-align: left;
  overflow-y: scroll;
  z-index: 1;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

@media (max-width: 575px) {
  .search-wrapper .search-box-wrapper input,
  .search-wrapper .search-box-wrapper .search-results {
    width: 80vw;
  }
}

@media (min-width: 576px) {
  .search-wrapper .search-box-wrapper input,
  .search-wrapper .search-box-wrapper .search-results {
    width: 50vw;
  }
}
