@import "assets/css/common.css";
@import "assets/css/pages.css";

* {
  font-family: "Poppins", sans-serif;
  font-display: swap;
}

body {
  margin: 0;
  background-color: var(--color-gray-100) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}
